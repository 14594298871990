import React from 'react'
import { Box, Typography } from '@mui/material'

export default function AcademicServices() {

  return (
    <Box>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <Typography sx={{ fontSize: '1.1rem', marginRight: '1px' }}>
          <b>Reviewer</b>
        </Typography>
        <Typography sx={{ fontSize: '1rem' }}>
            : FMDM@NeurIPS 2023, ICML 2024
        </Typography>
      </Box>
    </Box>
  )
}