import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

export default function About({ isMobile }) {

  const AboutBox = styled(Box)(({ theme }) => ({
    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '80px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column', marginTop: '40px',
    },
  }))

  const AboutSubBox = styled(Box)(({ theme }) => ({
    marginRight: '100px',
    [theme.breakpoints.down('md')]: {
      marginRight: 0
    },
  }))

  const SummaryBox = styled(Box)(({ theme }) => ({
    marginTop: '40px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  }))

  const SummaryContent1 = styled(Typography)(() => ({
    fontSize: '1rem',
  }))

  const SummaryContent2 = styled(Box)(({ theme }) => ({
    fontSize: '1rem',
    marginTop: '15px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginLeft: '0px',
      marginTop: '20px'
    },
  }))

  return (
    <AboutBox sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
        <AboutSubBox>
        <Typography sx={{ pb: 0, mb: 0 }} variant='h5' paragraph>
            👋 Hello, I&apos;m Beanie
        </Typography>
        <Typography sx={{ pb: 0, mb: 0 }} variant='h2' paragraph>
            Jeonghye Kim
        </Typography>
        <Typography sx={{ pb: 0, mb: 0 }} fontWeight={600} paragraph>
            KAIST Graduate school of Electrical Engineering
        </Typography>
        {isMobile && <img width='180px' height='200px' style={{ borderRadius: '50%', marginTop: '20px' }} src='static/common/jeonghye.png'></img>}
        <SummaryBox>
            <SummaryContent1>
            I am a <b>Ph.D. student at KAIST</b>, advised by Prof. <a href='https://sites.google.com/view/youngchulsung' target='_blank' rel="noreferrer">Youngchul Sung</a>.
            Previously, I received <b>B.S in Computer Science from KAIST</b> and I have <b>founded and operated an IT startup called DearPlants as the CEO</b>.
            </SummaryContent1>
            <SummaryContent2>
            My research is centered on <b>developing automated agents with high applicability in real-world contexts.</b> To achieve this goal, I am actively involved in <b>foundation models for decision making</b>, <b>language-based reinforcement learning</b>, and <b>reinforcement learning in environments with limited rewards.</b>
            </SummaryContent2>

        </SummaryBox>
        </AboutSubBox>
        {!isMobile && <img width='230px' height='260px' style={{ borderRadius: '50%', marginTop: '40px' }} src='static/common/jeonghye.png'></img>}
    </AboutBox>
  )
}

About.propTypes = {
  isMobile: PropTypes.bool.isRequired
}
