import React from 'react'
import { Box, Typography } from '@mui/material'

export default function WorkExperience() {

  return (
    <Box>
      <Box sx={{ pl:0.5 }}>
        <Typography
          sx={{
            fontSize: '0.9rem',
            color: 'grey.500',
            fontWeight: 'bold',
          }}
        >
          2020.06-2021.11
        </Typography>
        <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
          🪴 CEO @ Dearplants
        </Typography>
      </Box>

      <Box sx={{ mt: 2, pl:0.5 }}>
        <Typography
          sx={{
            fontSize: '0.9rem',
            color: 'grey.500',
            fontWeight: 'bold',
          }}
        >
          2018 winter
        </Typography>
        <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
          👩‍💼 Research Intern @ <a href='https://ccs-lab.github.io/' target='_blank' rel="noreferrer">CSS Lab, SNU</a>
        </Typography>
      </Box>
    </Box>
  )
}