import React, { useEffect } from 'react';
// @mui
import { styled as Mstyled } from '@mui/material/styles'
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Container,
  Box,
  Button,
  Typography
} from '@mui/material'
import GitHubIcon from '@mui/icons-material/GitHub';
import DescriptionIcon from '@mui/icons-material/Description';
// components
import ProjectPage from '../../components/ProjectPage'
import './Publications.css'

// ----------------------------------------------------------------------

const ProjectTypography = Mstyled(Typography)(({ theme, isMobile }) => ({
    marginTop: '40px',
    marginBottom: '10px',
    lineHeight: 1.1,
    fontSize: isMobile ? '2.1rem' : '2.7rem',
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
    },
  }))


// ----------------------------------------------------------------------

export default function DC() {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const keywords = 'Decision ConvFormer, Decision Transformer, Transformer, MetaFormer, RL, Reinforcement Learning';
  const description = 'Decision ConvFormer: Local Filtering in MetaFormer is Sufficient for Decision Making';

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = '/dc-favicon.ico';
  }, []);

  return (
    <ProjectPage title='DC' description={description} keywords={keywords} imgsrc='static/publications/metaformer.png' url='https://www.beanie00.com/publications/dc'>
      <Container maxWidth='lg' sx={{pb: 4}}>
        <ProjectTypography isMobile={isMobile} align='center'>
          <span style={{color: '#582ad8'}}>Decision ConvFormer</span>: Local Filtering in {!isMobile && <br/>}MetaFormer is Sufficient for Decision Making
        </ProjectTypography>
        <Typography align='center' sx={{mt: 2, fontSize: '1.3rem', fontWeight: 700}}>
            ICLR 2024 (Spotlight Presentation)
        </Typography>
        <Typography align='center' sx={{mt: 2, fontSize: '1.2rem'}}>
            <a className='dc' href='/' target='_blank' rel="noreferrer">Jeonghye Kim<sup>1</sup></a>,
            &nbsp;
            <a className='dc' href='https://suyoung-lee.github.io/' target='_blank' rel="noreferrer">Suyoung Lee<sup>1</sup></a>,
            &nbsp;
            <a className='dc' href='https://sites.google.com/view/wjkim1202/' target='_blank' rel="noreferrer">Woojun Kim<sup>2</sup>*</a>,
            &nbsp;
            <a className='dc' href='https://sites.google.com/view/youngchulsung' target='_blank' rel="noreferrer">Youngchul Sung<sup>1</sup>*</a>
        </Typography>
        <Typography align='center' sx={{mt: 0.2, fontSize: '1.1rem'}}>
            <sup>1</sup>&nbsp;KAIST&nbsp; <sup>2</sup>&nbsp;Carnegie Mellon University &nbsp;&nbsp;
        </Typography>
        <Typography align='center' sx={{mt: 0.2, fontSize: '1.1rem'}}>
            *indicates equal corresponding.
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 2}}>
            <Button variant="contained" startIcon={<DescriptionIcon />} color="dc" onClick={() => window.open('https://arxiv.org/abs/2310.03022', "_blank")} sx={{ color: 'white', marginRight: 0.5, borderRadius: 0.8 }}>
              PDF
            </Button>
            <Button variant="contained" startIcon={<GitHubIcon />} color="dc" onClick={() => window.open('https://github.com/beanie00/decision-convformer', "_blank")} sx={{ color: 'white', marginRight: 0, borderRadius: 0.8 }}>
              Code
            </Button>
        </Box>
        {/* <img width="100%" style={{ marginTop: '40px' }} src='/static/publications/dc/dc_architecture.png'/> */}
        <Typography sx={{mt: 5, mb: 1, fontSize: '1.8rem', fontWeight: 700}}>
            Abstract
        </Typography>
        <Typography sx={{fontSize: '1.1rem'}}>
            The recent success of Transformer in natural language processing has sparked its use in various domains. In offline reinforcement learning (RL), Decision Transformer (DT) is emerging as a promising model based on Transformer. However, we discovered that the attention module of DT is not appropriate to capture the inherent local dependence pattern in trajectories of RL modeled as a Markov decision process. To overcome the limitations of DT, we propose a novel action sequence predictor, named Decision ConvFormer (DC), based on the architecture of MetaFormer, which is a general structure to process multiple entities in parallel and understand the interrelationship among the multiple entities. DC employs local convolution filtering as the token mixer and can effectively capture the inherent local associations of the RL dataset. In extensive experiments, DC achieved state-of-the-art performance across various standard RL benchmarks while requiring fewer resources. Furthermore, we show that DC better understands the underlying meaning in data and exhibits enhanced generalization capability.
        </Typography>
        <hr className='dc'/>
        <Typography sx={{mt: 5, fontSize: '1.8rem', fontWeight: 700}}>
          Motivation
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <ul style={{fontSize: '1.1rem', paddingLeft: '25px'}}>
            <li>Offline RL can be viewed as sequence modeling problem and a representative work is <b><span style={{color: '#BF3D3F'}}>Decision Transformer</span></b> which employs an attention module.</li>
            <li>Offline RL data has an inherent pattern of <b>local association</b> due to the <b>Markovian property</b>.</li>
              <div style={{ textAlign: 'center'}}><img width={isMobile ? "100%": "65%"} style={{ marginTop: '10px' }} src='/static/publications/dc/markov_property.png'/></div>
          </ul>
        </Box>
        <Typography sx={{ textAlign: 'center', fontSize: '1.2rem'}}>“Is the <b><span style={{color: '#BF3D3F'}}>attention module</span></b> still an appropriate <b>local-association identifying structure</b> for data sequences of <b><u>Markov Decision Process?</u></b>”</Typography>
        <div style={{ textAlign: 'center'}}>
          {isMobile ? <img width={isMobile ? "100%": "65%"} style={{ marginTop: '10px' }} src='/static/publications/dc/attention_analysis_mobile.png'/>
          : <img width={isMobile ? "100%": "65%"} style={{ marginTop: '10px' }} src='/static/publications/dc/attention_analysis.png'/>}
        </div>
        <Typography sx={{ textAlign: 'center', fontSize: '1.1rem'}}>The <b><span style={{color: '#BF3D3F'}}>attention map</span></b> of the <b><span style={{color: '#BF3D3F'}}>Decision Transformer</span></b> does not capture local associations well.</Typography>
        <Typography sx={{ textAlign: 'center', color: '#31247c', fontSize: '1.2rem', mt: 3, background: '#ECE7F2', pl: 2, pr: 2, pt: 1, pb: 1, borderRadius: '6px', fontWeight: 500 }}>To properly learn the local association, we propose a new module {!isMobile && <br className='dc'/>} focusing on <span style={{fontWeight: 900}}>past few timesteps</span> and operating <span style={{fontWeight: 900}}>independently from the input sequence</span>.</Typography>

        <hr className='dc'/>
        <Typography sx={{mt: 5, fontSize: '1.8rem', fontWeight: 700}}>
          Model : Decision ConvFormer
        </Typography>

        <Typography sx={{mt: 2, fontSize: '1.4rem', fontWeight: 700}}>
          Base Architecture : MetaFormer
        </Typography>
        <Typography sx={{mt: 0.2, fontSize: '1.1rem'}}>
          <b>MetaFormer</b> is a general architecture that takes multiple entities in parallel, understands their interrelationship while minimizing information loss.
          <b>The DC network architecture adopts a MetaFormer</b> where the <b>token mixer of the MetaFormer is given by a convolution module</b>.
        </Typography>
        <div style={{ textAlign: 'center'}}><img width={isMobile ? "100%": "58%"} style={{ marginTop: '10px' }} src='/static/publications/dc/metaformer.png'/></div>

        <Typography sx={{mt: 2.5, fontSize: '1.4rem', fontWeight: 700}}>
          Token Mixer : 1D Depthwise Convolution Module
        </Typography>
        <Typography sx={{mt: 0.2, fontSize: '1.1rem'}}>
          Considering the disparity among state, action, and RTG embeddings, we use <b>three separate convolution filters</b> for each hidden dimension
        </Typography>
        <div style={{ textAlign: 'center'}}><img width={isMobile ? "100%": "65%"} style={{ marginTop: '10px' }} src='/static/publications/dc/token_mixer.png'/></div>

        <hr className='dc'/>
        <Typography sx={{mt: 5, fontSize: '1.8rem', fontWeight: 700}}>
          Results : Offline Performance
        </Typography>

        <Box sx={{ display: !isMobile && 'flex', flexDirection: !isMobile && 'row', textAlign: 'center'}}>
          <Box style={{width: isMobile? '100%' : '50%'}}>
            <Typography sx={{mt: 2.5, fontSize: '1.4rem', fontWeight: 700}}>MuJoCo & Antmaze</Typography>
            <img width="100%" style={{ marginTop: '10px' }} src='/static/publications/dc/results_mujoco_antmaze.png'/>
          </Box>
          <Box style={{width: isMobile? '100%' : '50%'}}>
            <Typography sx={{mt: 2.5, fontSize: '1.4rem', fontWeight: 700}}>Atari</Typography>
            <img width="100%" style={{ marginTop: '10px' }} src='/static/publications/dc/results_atari.png'/>
          </Box>
        </Box>

        <hr className='dc'/>
        <Typography sx={{mt: 5, fontSize: '1.8rem', fontWeight: 700}}>
          Results : Ablation Study
        </Typography>

        <Box sx={{ display: !isMobile && 'flex', flexDirection: !isMobile && 'row', textAlign: 'center', justifyContent: 'center'}}>
          <Box style={{width: isMobile? '100%' : '30%'}}>
            <Typography sx={{mt: 2.5, fontSize: '1.4rem', fontWeight: 700}}>Input Modal Dependency</Typography>
            <img width={isMobile ? "80%": "100%"} style={{ marginTop: '10px' }} src='/static/publications/dc/ablation_zeroing_out.png'/>
            <Typography sx={{lineHeight: 1.1}}><b><span style={{color: '#57469e'}}>DC</span></b> found out that RTG and state are important, whereas  <b><span style={{color: '#BF3D3F'}}>DT</span></b> seems not.</Typography>
          </Box>
          <Box style={{width: '10%'}}/>
          <Box style={{width: isMobile? '100%' : '40%'}}>
            <Typography sx={{mt: 2.5, fontSize: '1.4rem', fontWeight: 700}}>Generalization Capability</Typography>
            <img width="100%" style={{ marginTop: '10px' }} src='/static/publications/dc/ablation_generalization.png'/>
            <Typography sx={{lineHeight: 1.1}}><b><span style={{color: '#57469e'}}>DC</span></b> better understands the task context and better knows how to achieve the unseen desired higher target RTG than <b><span style={{color: '#BF3D3F'}}>DT</span></b>.</Typography>
          </Box>
        </Box>

        <hr className='dc'/>
        <Typography sx={{mt: 5, fontSize: '1.8rem', fontWeight: 700}}>
          Bibtex
        </Typography>
        <pre style={{ overflowX: 'auto', paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px', background: '#f1f1f1', borderRadius: '4px'}}>
        {`@inproceedings{kim2023decision,
  title={Decision Convformer: Local Filtering in MetaFormer is Sufficient for Decision Making},
  author={Kim, Jeonghye and Lee, Suyoung and Kim, Woojun and Sung, Youngchul},
  booktitle={International Conference on Learning Representations},
  year={2024}
}`}
</pre>

      </Container>
    </ProjectPage>
  )
}