import React, { useEffect } from 'react';
// @mui
import { styled as Mstyled } from '@mui/material/styles'
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Container,
  Box,
  Button,
  Typography
} from '@mui/material'
import GitHubIcon from '@mui/icons-material/GitHub';
import DescriptionIcon from '@mui/icons-material/Description';
// components
import ProjectPage from '../../components/ProjectPage'
import './Publications.css'

// ----------------------------------------------------------------------

const ProjectTypography = Mstyled(Typography)(({ theme, isMobile }) => ({
    marginTop: '40px',
    marginBottom: '10px',
    lineHeight: 1.1,
    fontSize: isMobile ? '2.2rem' : '2.6rem',
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
    },
  }))


// ----------------------------------------------------------------------

export default function VCS() {
  const isMobile = useMediaQuery("(max-width: 900px)");

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = '/vcs-favicon.ico';
  }, []);

  return (
    <ProjectPage title='VCS' project={true} >
      <Container maxWidth='lg' >
        <ProjectTypography align='center' isMobile={isMobile}>
            <span style={{color: '#50aa50'}}>V</span>alue-Aided <span style={{color: '#50aa50'}}>C</span>onditional <span style={{color: '#50aa50'}}>S</span>upervised Learning for Offline RL
        </ProjectTypography>
        {/* <Typography align='center' sx={{mt: 2, fontSize: '1.3rem', fontWeight: 700}}>
            ICML 2024
        </Typography> */}
        <Typography align='center' sx={{mt: 2, fontSize: '1.2rem'}}>
          <a className='vcs' href='/' target='_blank' rel="noreferrer">Jeonghye Kim<sup>1</sup></a>,
          &nbsp;
          <a className='vcs' href='https://suyoung-lee.github.io/' target='_blank' rel="noreferrer">Suyoung Lee<sup>1</sup></a>,
          &nbsp;
          <a className='vcs' href='https://sites.google.com/view/wjkim1202/' target='_blank' rel="noreferrer">Woojun Kim<sup>2</sup></a>,
          &nbsp;
          <a className='vcs' href='https://sites.google.com/view/youngchulsung' target='_blank' rel="noreferrer">Youngchul Sung<sup>1</sup></a>
        </Typography>
        <Typography align='center' sx={{mt: 0.2, fontSize: '1.1rem'}}>
          <sup>1</sup>&nbsp;KAIST&nbsp; <sup>2</sup>&nbsp;Carnegie Mellon University &nbsp;&nbsp;
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 2}}>
          <Button variant="contained" startIcon={<DescriptionIcon />} color="vcs" onClick={() => window.open('https://arxiv.org/abs/2402.02017', "_blank")} sx={{ color: 'white', marginRight: 0.5, borderRadius: 0.8 }}>
            PDF
          </Button>
          <Button variant="contained" startIcon={<GitHubIcon />} color="vcs" onClick={() => window.open('bb', "_blank")} sx={{ color: 'white', marginRight: 0, borderRadius: 0.8 }}>
            Code
          </Button>
        </Box>
        {/* <img width="100%" style={{ marginTop: '40px' }} src='/static/publications/dc/dc_architecture.png'/> */}
        <Typography sx={{mt: 5, fontSize: '1.8rem', fontWeight: 700}}>
          Abstract
        </Typography>
        <Typography>
          Offline reinforcement learning (RL) has seen notable advancements through return-conditioned supervised learning (RCSL) and value-based methods, yet each approach comes with its own set of practical challenges. Addressing these, we propose Value-Aided Conditional Supervised Learning (VCS), a method that effectively synergizes the stability of RCSL with the stitching ability of value-based methods. Based on the Neural Tangent Kernel analysis to discern instances where value function may not lead to stable stitching, VCS injects the value aid into the RCSL's loss function dynamically according to the trajectory return. Our empirical studies reveal that VCS not only significantly outperforms both RCSL and value-based methods but also consistently achieves, or often surpasses, the highest trajectory returns across diverse offline RL benchmarks. This breakthrough in VCS paves new paths in offline RL, pushing the limits of what can be achieved and fostering further innovations.
        </Typography>
        <hr className='vcs'/>
        <div style={{ textAlign: 'center'}}><img width={isMobile ? "100%": "70%"} style={{ marginTop: '15px' }} src='/static/publications/vcs/method.png'/></div>
      </Container>
    </ProjectPage>
  )
}