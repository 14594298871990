import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
// layouts
import Work from 'pages/Projects'
import MainLayout from './layouts'
//
import NotFound from './pages/Page404'
import CV from './pages/CV'
import DC from './pages/Publications/DC'
import VCS from './pages/Publications/VCS'

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <CV /> },
        { path: 'projects', element: <Work /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' /> },
      ],
    },
    { path: 'publications/dc', element: <DC /> },
    { path: 'publications/vcs', element: <VCS /> },
    { path: '*', element: <Navigate to='/404' replace /> },
  ])
}
