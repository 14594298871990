import React from 'react';
import { Box, Typography, Button, styled } from '@mui/material';
import PropTypes from 'prop-types';

const ResponsiveImage = styled('img')(() => ({
  borderWidth: '2px',
  borderColor: '#ddd',
  borderStyle: 'solid',
  borderRadius: '10px',
  marginRight: '20px',
}));

const PublicationItem = ({ isMobile, imageSrc, title, authors, status, conference, additionalInfo, paperLink, codeLink, projectLink }) => (
  <Box sx={{ flexDirection: !isMobile && 'row', display: !isMobile && 'flex', marginBottom: '5px', alignItems: 'center', mb:(!isMobile && 3.5) || (isMobile && 5) }}>
    <ResponsiveImage className="responsive-image" src={imageSrc} alt="Publication" />
    <Box>
      {title}
      <Typography sx={{ fontSize: '1rem' }}>
        {authors}
      </Typography>
      <Typography sx={{ fontSize: '1rem', display: 'flex', flexDirection: 'row' }}>
        {status} <span>&nbsp;</span>{conference && <><b>{conference}</b> {additionalInfo}</>}
      </Typography>
      <Box sx={{ mt: 1 }}>
        {paperLink && (
          <Button variant="outlined" onClick={() => window.open(paperLink, "_blank")} sx={{ p: 0.1, maxWidth: '50px', minWidth: '50px', marginRight: codeLink || projectLink ? 0.5 : 0, borderRadius: 0.8 }}>
            PDF
          </Button>
        )}
        {codeLink && (
          <Button variant="outlined" onClick={() => window.open(codeLink, "_blank")} sx={{ p: 0.1, maxWidth: '50px', minWidth: '50px', marginRight: projectLink ? 0.5 : 0, borderRadius: 0.8 }}>
            Code
          </Button>
        )}
        {projectLink &&
          <Button variant="outlined" onClick={() => window.open(projectLink, "_blank")} sx={{ p: 0.1, maxWidth: '100px', minWidth: '100px',borderRadius: 0.8 }}>
            Project Page
          </Button>
        }
      </Box>
    </Box>
  </Box>
);

PublicationItem.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  authors: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  conference: PropTypes.string.isRequired,
  additionalInfo: PropTypes.string.isRequired,
  paperLink: PropTypes.string,
  codeLink: PropTypes.string,
  projectLink: PropTypes.string
};

export default function Publications({ isMobile }) {
  const publications = [
    {
      imageSrc: 'static/publications/vcs.png',
      title: <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>Value-Aided Conditional Supervised Learning for Offline RL</Typography>,
      authors: <Typography><b>Jeonghye Kim</b>, Suyoung Lee, Woojun Kim, Youngchul Sung</Typography>,
      status: 'Under-review',
      paperLink: "https://arxiv.org/abs/2402.02017",
      projectLink: ""
    },
    {
      imageSrc: 'static/publications/dc.png',
      title: (
        <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
          <a href='https://openreview.net/forum?id=af2c8EaKl8' target='_blank' rel="noreferrer">
            Decision ConvFormer: Local Filtering in MetaFormer is Sufficient for Decision Making
          </a>
        </Typography>
      ),
      authors: <Typography><b>Jeonghye Kim</b>, Suyoung Lee, Woojun Kim*, Youngchul Sung*</Typography>,
      status: 'International Conference on Learning Representations',
      conference: '(ICLR) 2024',
      additionalInfo: <Typography sx={{ fontSize: '1rem', color: 'red', marginLeft: '8px' }}><b>Spotlight (Top 5%)</b></Typography>,
      paperLink: "https://arxiv.org/abs/2310.03022",
      codeLink: "https://github.com/beanie00/decision-convformer",
      projectLink: "./publications/dc"
    },
    {
      imageSrc: 'static/publications/lesson.png',
      title: (
        <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
          <a href="https://openreview.net/forum?id=vXcvrYJlVm" target='_blank' rel="noreferrer">
            LESSON: Learning to Integrate Exploration Strategies for Reinforcement Learning via an Option Framework
          </a>
        </Typography>
      ),
      authors: <Typography>Woojun Kim*, <b>Jeonghye Kim</b>*, Youngchul Sung (*: equal contribution)</Typography>,
      status: 'International Conference on Machine Learning',
      conference: '(ICML) 2023',
      paperLink: "https://arxiv.org/abs/2310.03342",
      codeLink: "https://github.com/beanie00/LESSON"
    }
  ];

  return (
    <Box>
      {publications.map((pub, index) => (
        <PublicationItem isMobile={isMobile} key={index} {...pub} />
      ))}
    </Box>
  );
}

Publications.propTypes = {
  isMobile: PropTypes.bool.isRequired
};